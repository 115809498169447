<template>
  <div
    class="messages-item"
    @click="$router.push(`/chat/?projectId=${message.projectId}`)"
    :style="{ '--color': message.messageColor }"
  >
    <div class="messages-item-top">
      <span class="name"
        >{{ message.creatorDepartmentName }} - {{ message.creatorName }}</span
      >
      <span class="date">{{ message.createdOn | formattingDateMessage }}</span>
    </div>
    <div class="messages-item-bottom">
      <div class="title">{{ message.projectName }}</div>
      <div class="description">{{ message.messageContent }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageItem',
  props: {
    message: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
