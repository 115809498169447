<template>
  <div class="table-templates">
    <div class="top">
      <div class="title">{{ title }}</div>
      <div class="action" v-if="isAction">
        <q-btn
          class="btn-new"
          icon-right="add"
          label="Nouveau modèle"
          @click="$router.push('/new-template')"
        />
      </div>
    </div>
    <div class="bottom">
      <div
        class="row"
        v-if="
          (isAllModels && getModels.length) ||
            (!isAllModels && getModelsLast.length)
        "
      >
        <q-table
          :data="
            isAllModels
              ? isSearch
                ? searchAll
                : getModels
              : isSearch
              ? searchLast
              : getModelsLast
          "
          :columns="columns"
          :hide-bottom="true"
          :pagination.sync="pagination"
          :sort-method="customSort"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              @click="$router.push(`/edit-template/${props.row.modelId}`)"
            >
              <q-td key="type" :props="props" class="td-type">
                <span
                  v-if="props.row.modelType === 'DOCX'"
                  class="word-icon"
                ></span>
                <span
                  v-if="props.row.modelType === 'EXCEL'"
                  class="excel-icon"
                ></span>
              </q-td>
              <q-td key="template" :props="props" class="td-template-name">
                {{ props.row.modelName }}
              </q-td>
              <q-td key="update" :props="props" class="td-update">
                {{ props.row.updatedOn | formattingDate }}
              </q-td>
              <q-td key="actions" :props="props" class="td-actions">
                <q-btn
                  class="btn-copy"
                  type="button"
                  @click="e => copy(e, props.row.modelId)"
                ></q-btn>
                <q-btn class="btn-edit" type="button"></q-btn>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <LoadingOrEmpty
        v-else
        :loading="isAllModels ? getLoadingModels : getLoadingModelsLast"
        :custom-empty="true"
      >
        <template v-slot:customEmpty>
          <div class="no-documents">Pas de modèle créé</div>
        </template>
      </LoadingOrEmpty>
    </div>
  </div>
</template>

<script>
import { format, getTime } from 'date-fns'
import { mapGetters, mapActions } from 'vuex'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  name: 'TableTemplates',
  components: { LoadingOrEmpty },
  props: {
    title: {
      type: String,
      default: ''
    },
    isAction: {
      type: Boolean,
      default: false
    },
    isAllModels: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchAll: [],
      isSearch: false,
      searchLast: [],
      columns: [
        { name: 'type', label: 'Type', field: 'type', align: 'left' },
        {
          name: 'template',
          label: 'Nom du modèle',
          field: 'template',
          sortable: true,
          align: 'left'
        },
        {
          name: 'update',
          label: 'Dernière màj.',
          field: 'update',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: 7
      }
    }
  },
  computed: {
    ...mapGetters('documents', [
      'getModels',
      'getLoadingModels',
      'getErrorModels',
      'getModelsLast',
      'getLoadingModelsLast',
      'getErrorModelsLast'
    ]),
    ...mapGetters('templates', ['getSearch'])
  },
  async created() {
    this.setSearchTemplates({ message: '' })
    this.isAllModels ? await this.fetchModels() : await this.fetchModelsLast()
    this.pagination.rowsPerPage = this.isAllModels
      ? this.getModels.length
      : this.getModelsLast.length
  },
  methods: {
    ...mapActions('documents', ['fetchModels', 'fetchModelsLast', 'duplicateModel']),
    ...mapActions('templates', ['setSearchTemplates']),
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    copy(e, model_id) {
      e.stopPropagation()
      const data = {
        modelId: model_id
      }
      this.duplicateModel(data).then(response => {
        this.$router.push(`/edit-template/${response.modelId}`)
      })
    },
    buildSearchLastMethod(message) {
      this.searchLast = this.getModelsLast.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.modelName.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    },
    buildSearchAllMethod(message) {
      this.searchAll = this.getModels.filter(item => {
        const updatedOn = item.updatedOn.replace(/\s/, 'T') || new Date()
        const date = format(new Date(updatedOn), 'dd/MM/yyyy')
        if (
          item.modelName.toLowerCase().indexOf(message) > -1 ||
          date.indexOf(message) > -1
        ) {
          return item
        }
      })
    },
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'template':
          return this.handlerSort(data, descending, 'modelName')
        case 'update':
          return this.handlerSort(data, descending, 'updatedOn')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        if (sortBy === 'updatedOn') {
          const xSortBy = x[sortBy].replace(/\s/, 'T') || new Date()
          const ySortBy = y[sortBy].replace(/\s/, 'T') || new Date()
          const xFormat = getTime(new Date(xSortBy))
          const yFormat = getTime(new Date(ySortBy))

          return xFormat > yFormat ? 1 : xFormat < yFormat ? -1 : 0
        }

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  },
  watch: {
    getErrorModels: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getErrorModelsLast: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getSearch: function(message) {
      if (message && message.length) {
        this.isSearch = true

        if (this.getModelsLast.length) {
          this.buildSearchLastMethod(message)
        }

        if (this.getModels.length) {
          this.buildSearchAllMethod(message)
        }
      } else {
        this.isSearch = false
      }
    }
  }
}
</script>
