var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-templates"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.isAction)?_c('div',{staticClass:"action"},[_c('q-btn',{staticClass:"btn-new",attrs:{"icon-right":"add","label":"Nouveau modèle"},on:{"click":function($event){return _vm.$router.push('/new-template')}}})],1):_vm._e()]),_c('div',{staticClass:"bottom"},[(
        (_vm.isAllModels && _vm.getModels.length) ||
          (!_vm.isAllModels && _vm.getModelsLast.length)
      )?_c('div',{staticClass:"row"},[_c('q-table',{attrs:{"data":_vm.isAllModels
            ? _vm.isSearch
              ? _vm.searchAll
              : _vm.getModels
            : _vm.isSearch
            ? _vm.searchLast
            : _vm.getModelsLast,"columns":_vm.columns,"hide-bottom":true,"pagination":_vm.pagination,"sort-method":_vm.customSort},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('q-tr',{attrs:{"props":props},on:{"click":function($event){return _vm.$router.push(`/edit-template/${props.row.modelId}`)}}},[_c('q-td',{key:"type",staticClass:"td-type",attrs:{"props":props}},[(props.row.modelType === 'DOCX')?_c('span',{staticClass:"word-icon"}):_vm._e(),(props.row.modelType === 'EXCEL')?_c('span',{staticClass:"excel-icon"}):_vm._e()]),_c('q-td',{key:"template",staticClass:"td-template-name",attrs:{"props":props}},[_vm._v(" "+_vm._s(props.row.modelName)+" ")]),_c('q-td',{key:"update",staticClass:"td-update",attrs:{"props":props}},[_vm._v(" "+_vm._s(_vm._f("formattingDate")(props.row.updatedOn))+" ")]),_c('q-td',{key:"actions",staticClass:"td-actions",attrs:{"props":props}},[_c('q-btn',{staticClass:"btn-copy",attrs:{"type":"button"},on:{"click":e => _vm.copy(e, props.row.modelId)}}),_c('q-btn',{staticClass:"btn-edit",attrs:{"type":"button"}})],1)],1)]}}],null,false,2516436720)})],1):_c('LoadingOrEmpty',{attrs:{"loading":_vm.isAllModels ? _vm.getLoadingModels : _vm.getLoadingModelsLast,"custom-empty":true},scopedSlots:_vm._u([{key:"customEmpty",fn:function(){return [_c('div',{staticClass:"no-documents"},[_vm._v("Pas de modèle créé")])]},proxy:true}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }