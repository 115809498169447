<template>
  <q-drawer
    side="right"
    v-model="drawer"
    show-if-above
    :mini="isMessageDrawerShown()"
    :width="400"
    :mini-width="minWidth"
    :breakpoint="1023"
    bordered
    content-class="bg-grey-3"
    class="messages"
  >
    <q-scroll-area class="fit messages-container">
      <slot name="actions"></slot>
      <div class="top">
        <div class="title">Messages</div>
        <div class="double-arrow-icon">
          <q-btn dense round unelevated @click="handlerToggle()">
            <div class="chat-status-text-hide">Masquer</div>
          </q-btn>
        </div>
        <div class="chat-button-retract" @click="handlerToggle()">
          <p class="chat-status-text-show">Afficher les messages</p>
          <div class="chat-icon"></div>
        </div>
        <div class="action">
          <q-btn
            :class="{ 'btn-see': true }"
            :disable="!getLastProjects.length"
            icon-right="trending_flat"
            label="Plus"
            @click="goToChat"
          />
        </div>
      </div>

      <div class="bottom">
        <q-select
          v-if="filter"
          label="Tous les projets"
          clearable
          filled
          :disable="!options.length"
          v-model="select"
          :options="options"
          ref="filter"
          @click.native="click"
          @dblclick.native="clear"
          @clear="clear"
          @input="selected"
        />
        <div class="messages-wrapper" v-if="listMessages.length" ref="messages">
          <MessagesItem
            v-for="message in listMessages"
            :key="message.messageId"
            :message="message"
          />
        </div>
        <div
          class="messages-wrapper"
          v-if="listMessagesByProjectId.length"
          ref="messages"
        >
          <MessagesItem
            v-for="message in listMessagesByProjectId"
            :key="message.messageId"
            :message="message"
          />
        </div>
        <LoadingOrEmpty
          v-if="!listMessages.length && !listMessagesByProjectId.length"
          :loading="!messagesByProjectId ? getLoading : getLoadingByProjectId"
          :custom-empty="true"
        >
          <template v-slot:customEmpty>
            <div class="messages-wrapper-empty">
              <img src="../../assets/icons/message-circle.svg" />
              <div>Aucun message pour ce projet</div>
              <p>Rédigez le premier message du projet</p>
            </div>
          </template>
        </LoadingOrEmpty>
      </div>

      <slot name="chat-actions"></slot>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty.vue'
import MessagesItem from '@/components/home/MessagesItem.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Messages',
  components: {
    LoadingOrEmpty,
    MessagesItem
  },
  props: {
    minWidth: {
      type: Number,
      default: 86
    },
    filter: {
      type: Boolean,
      default: true
    },
    messagesByProjectId: {
      type: Boolean,
      default: false
    },
    updateMessagesByProjectId: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: false,
      select: '',
      options: [],
      projectId: null,
      listMessages: [],
      listMessagesByProjectId: []
    }
  },
  created() {
    this.clearMessages()
    this.projectId = this.$route.params.projectId

    const isProject = this.messagesByProjectId

    if (isProject) {
      this.fetchMessagesByProjectId({
        projectId: this.projectId,
        sort: false
      }).then(() => {
        if (this.getMessages.length) {
          this.listMessages = this.getMessages
        }

        if (this.getMessagesByProjectId.length) {
          this.listMessagesByProjectId = this.getMessagesByProjectId
        }

        const uniqueIds = []
        this.options = this.getMessages.reduce((total, item) => {
          if (!uniqueIds.includes(item.projectId)) {
            uniqueIds.push(item.projectId)
            total.push({
              label: item.projectName,
              value: item.projectName
            })
          }

          return total
        }, [])
      })
    } else {
      this.fetchMessages().then(() => {
        if (this.getMessages.length) {
          this.listMessages = this.getMessages
        }

        if (this.getMessagesByProjectId.length) {
          this.listMessagesByProjectId = this.getMessagesByProjectId
        }

        const uniqueIds = []
        this.options = this.getMessages.reduce((total, item) => {
          if (!uniqueIds.includes(item.projectId)) {
            uniqueIds.push(item.projectId)
            total.push({
              label: item.projectName,
              value: item.projectName
            })
          }

          return total
        }, [])
      })
    }
  },
  computed: {
    ...mapGetters('messages', [
      'getIsOpen',
      'getMessages',
      'getLoading',
      'getError',
      'getMessagesByProjectId',
      'getLoadingByProjectId',
      'getErrorByProjectId'
    ]),
    ...mapGetters('projects', ['getLastProjects'])
  },
  methods: {
    ...mapActions('messages', [
      'fetchMessages',
      'toggleIsOpen',
      'fetchMessagesByProjectId',
      'clearMessages'
    ]),
    isMessageDrawerShown() {
      return !this.drawer || !this.getIsOpen
      // console.log('isMessageDrawerShown')
      // console.log(this.$router.currentRoute.name)
      // if (this.$router.currentRoute.name == 'Projects') {
      //   console.log('Return TRUE')
      //   return true
      // } else {
      //   console.log('!this.getIsOpen : ', !this.getIsOpen)
      //   console.log('!this.drawer : ', !this.drawer)
      // }
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    },
    selected(selected) {
      if (this.getMessages.length) {
        this.listMessages = !selected
          ? this.getMessages
          : this.getMessages.filter(item => item.projectName === selected.value)
      }

      if (this.getMessagesByProjectId.length) {
        this.listMessagesByProjectId = !selected
          ? this.getMessagesByProjectId
          : this.getMessagesByProjectId.filter(
              item => item.projectName === selected.value
            )
      }
    },
    clear() {
      this.$refs.filter.blur()
    },
    click() {
      const element = document.querySelector('.q-menu--square')

      if (element?.classList.contains('q-transition--fade-leave-active')) {
        this.clear()
      }
    },
    handlerToggle() {
      this.toggleIsOpen(this.getIsOpen)

      if (this.getIsOpen) {
        const scrollBottom = this.scrollBottom
        setTimeout(function() {
          scrollBottom()
        }, 200)
      }
    },
    async scrollBottom() {
      await this.$refs.messages
      const messages = this.$refs.messages
      if (messages) messages.scrollTop = messages.scrollHeight
    },
    goToChat() {
      const projectId = this.$route.params.projectId
      if (projectId) {
        this.$router.push(`/chat/?projectId=${projectId}`)
      } else {
        this.$router.push('/chat')
      }
    }
  },
  watch: {
    updateMessagesByProjectId: async function(status) {
      if (status) {
        await this.fetchMessagesByProjectId({
          projectId: this.projectId,
          sort: false
        })
        this.listMessagesByProjectId = this.getMessagesByProjectId

        const messages = await this.$refs.messages
        if (messages) messages.scrollTop = messages.scrollHeight

        this.$emit('messagesUpdated')
      }
    },
    getErrorByProjectId: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    getError: function(message) {
      if (message && message.length) this.showNotify(message)
    },
    listMessages: function() {
      this.scrollBottom()
    },
    listMessagesByProjectId: function() {
      this.scrollBottom()
    }
  }
}
</script>
