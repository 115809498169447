<template>
  <div :class="{ templates: true, 'is-close-messages': !getIsOpen }">
    <q-page-container>
      <q-page>
        <div class="column" style="height: calc(100vh - 86px)">
          <div>
            <TableTemplates title="Modèles utilisés récemment" :is-action="true" />
          </div>
          <div>
            <TableTemplates title="Tous les modèles" :is-all-models="true" />
          </div>
        </div>
      </q-page>
    </q-page-container>

    <Messages />
  </div>
</template>

<script>
import Messages from '@/components/global/Messages.vue'
import TableTemplates from '@/components/templates/TableTemplates.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TableTemplates',
  components: {
    TableTemplates,
    Messages
  },
  computed: {
    ...mapGetters('messages', ['getIsOpen'])
  }
}
</script>
