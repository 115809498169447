import { render, staticRenderFns } from "./Messages.vue?vue&type=template&id=10318812&"
import script from "./Messages.vue?vue&type=script&lang=js&"
export * from "./Messages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QTr from 'quasar/src/components/table/QTr.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDrawer,QScrollArea,QBtn,QSelect,QMenu,QTr});
